import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import "../styles/styles.scss"
import Img from "gatsby-image"
import HeroAbout from "../components/HeroAbout"
import Fusion from "../components/Fusion"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const About = () => {
  const pics = useStaticQuery(graphql`
    query {
      home: file(relativePath: { eq: "HomePractice.jpg" }) {
        childImageSharp {
          fixed(width: 360) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      band: file(relativePath: { eq: "TheBandSing.jpg" }) {
        childImageSharp {
          fixed(width: 360) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      laugh: file(relativePath: { eq: "Laugh.jpg" }) {
        childImageSharp {
          fixed(width: 360) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      coach: file(relativePath: { eq: "DanLe.jpg" }) {
        childImageSharp {
          fixed(width: 360) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo
        title="About"
        description="The Band Academy started early 2019 with a small group of
                    absolute beginner musicians who wanted to try out a new way
                    of learning music. We are continually coming up with new
                    ideas to stimulate learning and cultivate a love for music."
      />
      <HeroAbout />
      <div className="about">
        <div className="aboutWrap">
          <div className="para">
            <div className="words">
              <div className="wordInnerZero">
                <div className="wordWrap">
                  <h2>It's Just The Beginning</h2>
                  <p>
                    The Band Academy started early 2019 with a small group of
                    absolute beginner musicians who wanted to try out a new way
                    of learning music. We are continually coming up with new
                    ideas to stimulate learning and cultivate a love for music.
                  </p>
                </div>
                <div className="imgWrap">
                  <Img
                    width="340"
                    height="340"
                    className="image"
                    fixed={pics.home.childImageSharp.fixed}
                  />
                </div>
              </div>
              <div className="wordInnerThree">
                <div className="imgWrap">
                  <Img
                    width="340"
                    height="340"
                    className="image"
                    fixed={pics.laugh.childImageSharp.fixed}
                  />
                </div>
                <div className="wordWrap">
                  <h2>You</h2>
                  <p>
                    I believe there are many adults with instruments in their
                    homes gathering dust not because they don't have the talent
                    but because they haven't yet seen a clear direction for
                    their musical aspirations.
                  </p>
                  <p>
                    It's also challenging to form a band or find other musicians
                    at a similar level to practice with. The Band Academy aims
                    to fix this and provides a place for adult amateur musicians
                    to go and find other musicians to connect with.
                  </p>
                </div>
              </div>
              <div className="wordInnerTwo">
                <div className="wordWrap">
                  <h2>The Coach</h2>
                  <p>
                    I like to think of the band a bit like a coach would think
                    of his team. I look for peoples strengths and weaknesses in
                    order to try and work out where best I can help them.
                  </p>
                  <p>
                    The coaches job is to become redundant as the role is really
                    about helping to spark off the inner desire to grow as a
                    musician and when this happens who knows how far you can
                    take it.
                  </p>
                </div>
                <div className="imgWrap">
                  <Img
                    width="340"
                    height="340"
                    className="image"
                    fixed={pics.coach.childImageSharp.fixed}
                  />
                </div>
              </div>

              <div className="wordInnerOne">
                <div className="imgWrap">
                  <Img
                    width="340"
                    height="340"
                    className="image"
                    fixed={pics.band.childImageSharp.fixed}
                  />
                </div>
                <div className="wordWrap">
                  <h2>The Band</h2>
                  <p>
                    All who train at The Band Academy are in The Band. We are
                    aiming to create a positive and encouraging culture that
                    inspires, motivates and educates everyone to get better.
                  </p>
                  <p>
                    Music can be a selfless pursuit when you decide to serve the
                    song and be the best band member you can be. Your ability as
                    a musician will flourish along with your enjoyment of music
                    when you take this approach.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Fusion
        concept="The Three Elements of The Band Academy"
        one="The Band"
        two="You"
        three="The Coach"
        title="The Band Academy"
      />
    </Layout>
  )
}

export default About
